nav {
  display: flex;
  align-items: center;
  padding: 1rem clamp(1.5rem, -0.649rem + 8.11vw, 3rem);
  position: sticky;
  top: 0;
  width: 100vw;
  overflow: hidden;
  z-index: 999;
  background-color: #313846;
  box-shadow: 0 0 18px 0.5px rgba(0, 0, 0, 0.8) !important;
}
nav .title {
  font-weight: 500;
  font-size: clamp(1.4rem, 0.542vw + 1.292rem, 1.8rem);
  color: white;
}

nav .nav-tabs {
  display: flex;
  gap: 1.8rem;
  font-size: 1.5em;
  margin-left: auto;
  color: white;
}

nav .nav-tabs svg {
  cursor: pointer;
}

nav .nav-tabs svg:hover {
  color: yellow;
}
nav .mobile-menu {
  display: none;
  font-size: 1.5rem;
  color: white;
  margin-left: auto;
}

@media screen and (max-width: 475px) {
  nav .nav-tabs {
    display: none;
  }
  nav .nav-tabs.active {
    display: flex;
    position: fixed;
    flex-direction: column;
    top: 62px;
    right: 8px;
    background: rgba(53, 69, 100, 1);
    padding: 1rem;
  }
  nav .mobile-menu {
    display: block;
  }
}
