.checkout-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
}
.checkout-page > h2 {
  margin: 2rem 0;
  text-align: center;
}
.checkout-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
}

.address-items {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
