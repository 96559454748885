/* .cart-container div {
  border: 2px solid red;
} */
.wishlist-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
}
.wishlist-container > h2 {
  margin: 2rem 0;
  text-align: center;
}

.wishlist-items > h1 {
  font-size: 1.2rem;
  font-size: clamp(1.2rem, 0.353rem + 4.71vw, 2rem);
}
.wishlist-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 2rem;
}
