.signup-container {
  display: grid;
  place-items: center;
  height: calc(100vh - 6rem);
}

.signup-container .signup-card {
  display: flex;
  flex-direction: column;
  width: 22rem;
  max-width: 90vw;
  padding: 2rem;
  gap: 1em;
  box-shadow: 0 7px 29px 0 rgba(100, 100, 111, 0.2);
}

.signup-container .signup-card h2 {
  text-align: center;
  margin-top: 0;
}

.signup-container .signup-card .input-wrapper {
  display: flex;
  flex-direction: column;
}

.signup-container .signup-card label {
  font-size: 0.8rem;
  margin-bottom: 0.4rem;
}

.signup-container .signup-card input {
  border-radius: 5px;
  border: 2px solid #dfdfdf;
  font-size: 0.85rem;
  font-family: inherit;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.signup-container .signup-card input:hover,
.signup-container .signup-card input:focus {
  border-color: rgba(53, 69, 100, 1);
}

.signup-container .signup-card button {
  cursor: pointer;
  padding: 0.5rem;
  font-weight: 700;
  background-color: rgb(64, 83, 120);
  color: white;
  border: 0;
}

.signup-container .signup-card button:hover {
  background-color: rgba(0, 138, 221, 1);
}

.signup-container .signup-card .login {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 0.85rem;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 600;
  gap: 0.2rem;
  text-align: center;
}
