@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --shadow1: rgba(90, 90, 90, 0.35) 0px 5px 15px;
}

::-webkit-scrollbar {
  display: none;
}

input:focus {
  outline: none;
}

button {
  font-family: inherit;
}

body {
  margin: 0;
  font-family: "JetBrains Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
