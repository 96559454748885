.category-container {
  padding: 1.5rem;
}

.category-container > h1 {
  font-size: 1.5rem;
  font-size: clamp(1.2rem, 0.714rem + 3.429vw, 2rem);
}

.category-items {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  padding: 1rem;
  justify-content: center;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 0.5rem;
}

@media screen and (max-width: 390px) {
  .category-items {
    gap: 2rem 1rem;
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .carousel img {
    height: 30vh;
    width: 100vw !important;
  }
}
