.search-bar {
  position: absolute;
  top: 80px;
  right: 10px;
  background: white;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  border: 1px solid #313846;
  background-color: #313846;
  border-radius: 10px;
  gap: 0.1rem;
  z-index: 999;
}

.search-bar input {
  background: rgba(237, 242, 254, 1);
  padding: 0.6rem 1.2rem;
  font-size: 0.95rem;
  font-weight: 500;
  font-family: inherit;
  color: grey;
  border: 0;
  border-radius: 8px;
}

.search-bar div {
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: 22px;
  min-width: 50px;
  height: 100%;
  padding: 0.62rem 0;
  font-weight: 600;
}

.search-bar div svg path {
  stroke: rgba(237, 242, 254, 1);
  stroke-width: 3px;
}
