.filter-menu {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  position: fixed;
  width: 17rem;
  height: 100%;
  padding: 1rem 2rem 100px 1.5rem;
  overflow-y: auto;
  background: white;
  border-right: 1px solid #c2c2c2;
}

.filter-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter-header h4 {
  margin: 0.5rem 0;
}
.filter-header span:nth-child(2) {
  cursor: pointer;
  font-size: 0.85rem;
  border-bottom: 1px solid gray;
  color: gray;
  margin-left: auto;
}

.filter-category .category-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.4rem;
  font-size: 0.85em;
  margin-top: 0.2rem;
}

.sort-price .sort-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.4rem;
  font-size: 0.85em;
  margin-top: 0.2rem;
}

.rating-item {
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
}

.rating-item :nth-child(3) {
  width: 3.25rem;
}

.filter-menu > .close-button {
  display: none;
  background-color: #dc2626;
  color: white;
  font-weight: 500;
  align-self: flex-end;
  border: 0;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

@media screen and (max-width: 880px) {
  .filter-menu {
    display: none;
    width: 22rem;
    max-width: 90vw;
  }
  .filter-menu.active {
    display: flex;
    height: fit-content;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    padding: 1rem 1.5rem 2rem 1.5rem;
    border: 0;
    box-shadow: 0 2px 10px 0 rgba(100, 100, 111, 0.5);
  }

  .filter-menu > .close-button {
    cursor: pointer;
    margin-top: 1rem;
    display: block;
  }
}
