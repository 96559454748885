.loader-wrapper {
  position: fixed;
  top: 0px;
  z-index: 999999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.377);
  display: grid;
  place-items: center;
}

.loader-wrapper svg {
  height: 165px;
}

.loader-wrapper svg path {
  fill: rgb(1, 148, 240);
}
