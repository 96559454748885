.profile-card {
  width: 24rem;
  max-width: 94vw;
  box-shadow: 0 6px 20px 0 rgba(100, 100, 111, 0.2);
  padding: 0.8rem 1.5rem;
  border-top: 3px solid rgb(66, 99, 165);
}

.profile-card .user-details h4 {
  padding-bottom: 0.2rem;
  border-bottom: 1.5px solid black;
  font-weight: 800;
  width: fit-content;
}

.profile-card .user-details div {
  margin-bottom: 0.85rem;
}

.profile-card .user-details div span {
  font-weight: 600;
}

.profile-card button {
  cursor: pointer;
  padding: 0.5rem 1.25rem;
  font-size: 0.9em;
  font-weight: 800;
  color: white;
  background-color: rgb(222, 35, 35);
  border: 0;
  border-radius: 5px;
  margin: 1.5em 0;
}
